import React, { Component } from "react"

import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"

export default class RenameXcodeProjectIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='guide' fullPath='guide/rename-xcode-project' pageTitle="Rename Xcode Project" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						<h2>Steps</h2>
						<ol type="A">
							<li>
								<h3>Rename the project</h3>
								<ol>
									<li>Click on the project you want to rename in the <strong>Project navigator</strong> in the left panel of the Xcode window.</li>
									<li>In the right panel, select the <strong>File inspector</strong> and the name of your project should be found under <strong>Identity and Type</strong>. Change it to your new name.</li>
									<li>When the dialog asks whether to rename or not rename the projects content items, click <strong>Rename</strong>.
																</li>
								</ol>
							</li>
							<li>
								<h3>Rename the scheme</h3>
								<ol>
									<li>At the top of the window, next to the <strong>Stop</strong> button, there is a scheme for your product under its old name; click on it, then choose <strong>Manage Schemes…</strong>.</li>
									<li>Click on the old name in the scheme and it will become editable; change the name and click <strong>Close</strong>.</li>
								</ol>
							</li>
							<li>
								<h3>Rename the folders</h3>
								<ol>
									<li>Quit Xcode.</li>
									<li>In the correctly-named master folder, there is a newly-named .xcodeproj file with the wrongly-named OLD folder. Rename the OLD folder to your new name</li>
									<li>If applicable, rename OLD.xcworkspace to NEW.xcworkspace.</li>
									<li>If you use Git, you could run git mv oldname newname so that Git recognizes this is a move, rather than deleting/adding new files</li>
									<li>Re-open the project in Xcode and you will see a warning <strong>The folder OLD does not exist</strong>; dismiss the warning.</li>
									<li>In the <strong>Project navigator</strong> in the left-hand panel, click on the top-level OLD folder.</li>
									<li>In the right-hand panel, under <strong>Identity and Type</strong>, change the <strong>Name</strong> field from the OLD name to the new name.</li>
									<li>Just below that field is a <strong>Location</strong> menu. Click on the nearby folder icon and choose the renamed folder.</li>
								</ol>
							</li>
							<li>
								<h3>Rename the Build plist data</h3>
								<ol>
									<li>Click on the project in the <strong>Project navigator</strong> on the left, and in the main panel select <strong>Build Settings</strong>.</li>
									<li>Search for <strong>plist</strong> in the settings.</li>
									<li>In the Packaging section, you will see Info.plist and Product Bundle Identifier.</li>
									<li>If there is a name entered in Info.plist, update it.</li>
									<li>Do the same for Product Bundle Identifier.</li>
									<li>If you use SwiftUI, search for <strong>Development Assets</strong> and update the path.</li>
								</ol>
							</li>
							<li>
								<h3>Update CocoaPods</h3>
								<ol>
									<li>In XCode: choose and edit Podfile from the project navigator. You should see a target clause with the OLD name. Change it to NEW.</li>
									<li>Quit XCode.</li>
									<li>In the project folder, delete the OLD.podspec file.</li>
									<li>rm -rf Pods/</li>
									<li>Run pod install.</li>
									<li>Open XCode.</li>
									<li>Click on your project name in the project navigator.</li>
									<li>In the main pane, switch to the Build Phases tab.</li>
									<li>Under Link Binary With Libraries, look for libPods-OLD.a and delete it.</li>
									<li>If you have an objective-c Bridging header go to Build settings and change the location of the header from OLD/OLD-Bridging-Header.h to NEW/NEW-Bridging-Header.h</li>
								</ol>
							</li>
							<li>
								<h3>Repeat C for Tests</h3>
							</li>
							<li>
								<h3>Repeat C for CoreData</h3>
							</li>
						</ol>
						<h2>Reference</h2>
						<ul>
							<li><a className={"visiblelink"} target="_blank" rel="noopener noreferrer" href='https://stackoverflow.com/a/35500038'>Stackoverflow: How do I completely rename an Xcode project (i.e. inclusive of folders)?</a></li>
						</ul>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



